/**
 * Copyright 2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Input } from "@nordcloud/gnui";
import { useGenerateDatesFromCron } from "~/views/plans/hooks/useGenerateDatesFromCron/useGenerateDatesFromCron";
import { PlanData, PlanField } from "../constants";
import { FormField, TimeUnits } from "../types";
import { adjustInvalidCronMessage, convertToMinutes } from "./utils";

type Props = {
  expression: string;
  timezone: string;
  offset: number;
  offsetUnit: TimeUnits;
  setOffset: Dispatch<SetStateAction<number>>;
  setCronNextDates: Dispatch<SetStateAction<string[]>>;
  setPlanData?: Dispatch<SetStateAction<PlanData>>;
};

export function CronDelayInput({
  expression,
  timezone,
  offset,
  offsetUnit,
  setOffset,
  setCronNextDates,
  setPlanData,
}: Props) {
  const { control, setError, clearErrors } = useFormContext();

  const { generateDatesFromCron } = useGenerateDatesFromCron({
    setCronNextDates: setCronNextDates,
  });

  const generateCronDates = async (value: number) => {
    const offsetInMinutes = convertToMinutes(value, offsetUnit);

    const result = await generateDatesFromCron({
      cron_expression: expression,
      location: timezone,
      offset_in_minutes: offsetInMinutes,
    });

    const invalidCronMessage =
      result.data?.generateDatesFromCron.validationMsg ||
      result.data?.generateDatesFromCron.error;

    if (invalidCronMessage) {
      setError(FormField.SCHEDULE_CRON, {
        message: adjustInvalidCronMessage(invalidCronMessage),
      });

      return;
    }

    setPlanData?.((prevPlanData) => ({
      ...prevPlanData,
      [PlanField.SCHEDULE_PLAN]: {
        ...prevPlanData[PlanField.SCHEDULE_PLAN],
        [FormField.SCHEDULE_OFFSET]: offsetInMinutes,
      },
    }));

    clearErrors(FormField.SCHEDULE_CRON);
  };

  const handleChange = (delay: string) => {
    const offsetValue = Number(delay);
    setOffset(offsetValue);

    if (expression && timezone) {
      generateCronDates(offsetValue);
    }
  };

  return (
    <Controller
      name={FormField.SCHEDULE_OFFSET}
      control={control}
      render={({ field: { value, onChange } }) => (
        <Input
          type="text"
          id={FormField.SCHEDULE_OFFSET}
          name={FormField.SCHEDULE_OFFSET}
          value={offset || value}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            onChange(event.target.value);
            handleChange(event.target.value);
          }}
        />
      )}
    />
  );
}
