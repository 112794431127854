/**
 * Copyright 2023-2024 Nordcloud Oy or its affiliates. All Rights Reserved.
 */

import { Dispatch, SetStateAction, useState } from "react";
import { useFormContext } from "react-hook-form";
import { theme, FlexContainer, Label, Text } from "@nordcloud/gnui";
import { FormGroup } from "~/components";
import { PlanExtended } from "~/views/plans/PlanDetails/PlanProvider";
import { PlanData } from "../constants";
import { FormField, TimeUnits } from "../types";
import { CronDelayInput } from "./CronDelayInput";
import { CronDelayUnitDropDown } from "./CronDelayUnitDropdown";
import { CronExpressionInput } from "./CronExpressionInput";
import { CronTimezoneDropDown } from "./CronTimezoneDropdown";
import { CronNextDates } from "./NextDateCron";
import { ReadableCron } from "./ReadableCron";

type Props = {
  planData?: Partial<PlanExtended>;
  planDataForm?: PlanData;
  setPlanData?: Dispatch<SetStateAction<PlanData>>;
  nextDates?: string[];
};

export function ScheduleCron({
  planData,
  planDataForm,
  setPlanData,
  nextDates,
}: Props) {
  const [timezone, setTimezone] = useState<string>(
    planDataForm?.schedule_plan?.scheduleTimezone ??
      planData?.scheduleTimezone ??
      ""
  );

  const [cronExpression, setCronExpression] = useState<string>(
    planDataForm?.schedule_plan?.scheduleCron ?? planData?.scheduleCron ?? ""
  );

  const [offset, setOffset] = useState<number>(
    planDataForm?.schedule_plan?.scheduleOffset ?? planData?.scheduleOffset ?? 0
  );

  const [offsetUnit, setOffsetUnit] = useState<TimeUnits>(
    planDataForm?.schedule_plan?.scheduleOffsetUnit ?? TimeUnits.minutes
  );

  const [cronNextDates, setCronNextDates] = useState<string[]>(nextDates ?? []);

  const {
    formState: { errors },
  } = useFormContext();

  return (
    <>
      <FormGroup error={errors[FormField.SCHEDULE_TIMEZONE]}>
        <Label css={{ marginBottom: 0 }} name="Plan Timezone" required />
        <Text
          mb={theme.spacing.spacing02}
          size="sm"
          color={theme.color.text.text02}
        >
          By default local timezone will be displayed. You can change it to:
        </Text>
        <CronTimezoneDropDown
          timezone={timezone}
          expression={cronExpression}
          offset={offset}
          offsetUnit={offsetUnit}
          setTimezone={setTimezone}
          setPlanData={setPlanData}
          setCronNextDates={setCronNextDates}
        />
      </FormGroup>

      <FormGroup>
        <Label name="Cron Window Start" htmlFor="scheduleCron" required />
        <FlexContainer
          mb={theme.spacing.spacing01}
          gap={theme.spacing.spacing03}
          alignContent="flex-start"
        >
          <div css={{ minWidth: "12rem" }}>
            <CronExpressionInput
              timezone={timezone}
              cronExpression={cronExpression}
              offset={offset}
              offsetUnit={offsetUnit}
              setCronExpression={setCronExpression}
              setPlanData={setPlanData}
              setCronNextDates={setCronNextDates}
            />
          </div>
          <ReadableCron cronExpression={cronExpression} />
        </FlexContainer>
        <div css={{ height: "2rem" }}>
          <CronNextDates dates={cronNextDates} />
          <Text size="sm" color={theme.colors.danger} tag="div">
            <>{errors[FormField.SCHEDULE_CRON]?.message}</>
          </Text>
        </div>
      </FormGroup>

      <FormGroup error={errors[FormField.SCHEDULE_OFFSET]}>
        <Label css={{ marginBottom: 0 }} name="Delay" />
        <Text
          mb={theme.spacing.spacing02}
          size="sm"
          color={theme.color.text.text02}
        >
          Choose a time to delay the plan execution after the scheduled cron.
        </Text>
        <FlexContainer
          mb={theme.spacing.spacing04}
          gap={theme.spacing.spacing03}
        >
          <div css={{ width: "5rem" }}>
            <CronDelayInput
              expression={cronExpression}
              timezone={timezone}
              offset={offset}
              offsetUnit={offsetUnit}
              setOffset={setOffset}
              setPlanData={setPlanData}
              setCronNextDates={setCronNextDates}
            />
          </div>
          <div>
            <CronDelayUnitDropDown
              expression={cronExpression}
              timezone={timezone}
              offset={offset}
              offsetUnit={offsetUnit}
              setOffsetUnit={setOffsetUnit}
              setPlanData={setPlanData}
              setCronNextDates={setCronNextDates}
            />
          </div>
        </FlexContainer>
      </FormGroup>
    </>
  );
}
